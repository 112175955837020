import React from "react";

const Location = () => {
  return (
    <>
      <section className="location_section">
        <div className="side_spacing section_top_small section_btm_large">
          <h2 className="text-center title_spacing">
            AT A LOCATION THAT’S #THEAIMCHANGER <br />
            IN EVERY SENSE – DOWNTOWN WAKAD
          </h2>
          <p className="text-center mb-0">
            The location works in the favour of the project naturally. Altitude
            is a centrally located landmark, perched premiumly between PCMC and
            PMC, at downtown Wakad.
          </p>
          <div className="row section_top_small">
            <div className="col-md-4 col-12">
              <div className="location-img">
                <img
                  src="/images/Location/Altitude-Location.webp"
                  alt="Altitude-Location"
                />
              </div>
            </div>
            <div className="col-md-8 content-box-center">
              <div className="row">
                <div className="col-md-4 col-6 custom-location-margin ">
                  <div className="text-center">
                    <img
                      className="location-icon"
                      src="/images/Location/Location-Icon-1.png"
                      alt=""
                    />
                    <p>
                      Blessing In Disguise – No Ongoing Metro Work In The
                      Periphery Of The Project
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-6 custom-location-margin">
                  <div className="text-center">
                    <img
                      className="location-icon"
                      src="/images/Location/Location-Icon-2.png"
                      alt=""
                    />
                    <p>Premium Residential Catchment In The Neighborhood</p>
                  </div>
                </div>
                <div className="col-md-4 col-6 custom-location-margin">
                  <div className="text-center">
                    <img
                      className="location-icon"
                      src="/images/Location/Location-Icon-3.png"
                      alt=""
                    />
                    <p>
                      Minimum Signals And Maximum Flyovers For Faster Traffic
                      Movement
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-6 custom-location-margin">
                  <div className="text-center">
                    <img
                      className="location-icon"
                      src="/images/Location/Location-Icon-4.png"
                      alt=""
                    />
                    <p>Natural 360 Connectivity To All Surrounding Locations</p>
                  </div>
                </div>
                <div className="col-md-4 col-12 custom-location-margin">
                  <div className="text-center">
                    <img
                      className="location-icon"
                      src="/images/Location/Location-Icon-5.png"
                      alt=""
                    />
                    <p>
                      Excellent Infrastructure With Wide Road Network Around
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Location;
