import React from "react";
import Privacypolicy from "../components/Privacypolicy";

const Privacy = () => {
  return (
    <>
      <Privacypolicy />
    </>
  );
};

export default Privacy;