import React from "react";

const Aim = () => {
  return (
    <>
      <section className="aim_section">
        <div className="side_spacing section_top_large section_btm_small">
          <h2 className="text-center title_spacing">
            CHANGING THE AIM BY REDUCING THE CARBON FOOTPRINT
          </h2>
          <p className="text-center mb-0">
            LEED rating system is a globally recognized symbol of
            sustainability, achievement and leadership. It emphasizes
            sustainable building design by the means of innovative methods
            applied while designing the project, use of maximum natural light
            and ventilation, improving indoor environment quality, efficient
            waste management and sourcing of sustainable materials, and water &
            energy conservation. LEED spaces use fewer energy and water
            resources, save money for businesses and taxpayers; reduce carbon
            emissions; and prioritize environmental and human health.
          </p>
        </div>
        <div className="container-fluid section_btm_small">
          <div className="row">
            <div className="col-md-4 px-0">
              <div className="bulb-img">
                <img src="/images/Aim-imgs/Bulb-Image-1.webp" alt="aim_1" />
              </div>
            </div>
            <div className="col-md-8 col-12 content-box-center right_section_spacing">
              <div className="container-fluid">
                <div className="row ps-md-4">
                  <div className="col-md-12 col-sm-6  custom-icon-margin">
                    <div className="icon-group">
                      <img
                        className="aim-icon"
                        src="/images/Aim-imgs/Icon-1.png"
                        alt="Aim-Icon-1"
                      />
                      <div>
                        <h5>A HEALTHIER ENVIRONMENT</h5>
                        <p>
                          Allergy-friendly filters where air quality-related
                          illnesses are reduced by as much as 50%
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-6  custom-icon-margin">
                    <div className="icon-group">
                      <img
                        className="aim-icon"
                        src="/images/Aim-imgs/Icon-2.png"
                        alt="Aim-Icon-2"
                      />
                      <div>
                        <h5>LOWER UTILITY COSTS</h5>
                        <p>
                          LEED-certified buildings are designed to consume less
                          water and electricity. Reduced utility use will have a
                          positive impact on your bottom line.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-6  custom-icon-margin">
                    <div className="icon-group">
                      <img
                        className="aim-icon"
                        src="/images/Aim-imgs/Icon-3.png"
                        alt="Aim-Icon-3"
                      />
                      <div>
                        <h5>ENHANCED RENTAL VALUE</h5>
                        <p>
                          LEED-certified buildings command the highest rents,
                          and vacancy rates for green buildings are an estimated
                          4% lower than non-green properties, globally.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-6  custom-icon-margin">
                    <div className="icon-group">
                      <img
                        className="aim-icon"
                        src="/images/Aim-imgs/Icon-4.png"
                        alt="Aim-Icon-4"
                      />
                      <div>
                        <h5>IMPROVED RECRUITMENT AND RETENTION</h5>
                        <p>
                          Millennials have been shown to place a high value on
                          working for employers that are green minded.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="icon-group">
                      <img
                        className="aim-icon"
                        src="/images/Aim-imgs/Icon-5.png"
                        alt="Aim-Icon-5"
                      />
                      <div>
                        <h5>A BOOST IN YOUR PUBLIC IMAGE</h5>
                        <p className="mb-0">
                          Communities tend to welcome and promote LEED certified
                          buildings. The structures are often well known even in
                          large cities. As a result, having your business housed
                          in one can benefit your brand image. Plus, occupying
                          environmentally friendly office space shows that your
                          company is green-minded, which can be a differentiator
                          between your organization and the competition.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Aim;
