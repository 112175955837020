import React from "react";

const Management = () => {
  return (
    <>
      <section className="management_section">
        <div className="side_spacing section_spacing overlay-dark-2">
          <h2 className="text-center text-white text-center title_spacing">
            BUILDING MANAGEMENT SERVICES
          </h2>
          <div className="row">
            <div className="col-sm-3 col-6">
              <div className="management-card">
                <div className="management-icons">
                  <img src="/images/chart-area-solid.webp" alt="" />
                </div>
                <p className="text-white text-center">
                  Monitoring Genset operation, Lifts and common area lighting
                </p>
              </div>
            </div>
            <div className="col-sm-3 col-6">
              <div className="management-card">
                <div className="management-icons">
                  <img src="/images/fire-extinguisher-solid.webp" alt="" />
                </div>
                <p className="text-white text-center">
                  Operations of the plumbing & fire fighting systems
                </p>
              </div>
            </div>
            <div className="col-sm-3 col-6">
              <div className="management-card">
                <div className="management-icons">
                  <img src="/images/charging-station-solid.webp" alt="" />
                </div>
                <p className="text-white text-center">
                  Monitoring of HVAC operations
                </p>
              </div>
            </div>
            <div className="col-sm-3 col-6">
              <div className="management-card">
                <div className="management-icons">
                  <img src="/images/car-battery-solid.webp" alt="" />
                </div>
                <p className="text-white text-center">
                  For energy conservation
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Management;
