import React from "react";

const Landmarks = () => {
  return (
    <>
      <section className="landmark_section">
        <div className="side_spacing section_spacing">
          <h2 className=" title_spacing text-center">
            OUR OTHER COMMERCIAL LANDMARKS
          </h2>
          <div className="row">
            <div className="col-md-6 custom-margin-2">
              <h5 class="text-center subtitle_spacing">
                DELIVERED - GRADE ‘B’ COMMERCIAL PROJECTS
              </h5>
              <div className="landmark-main">
                <div className="landmark-content-box">
                  <div className="landmark-img">
                    <img
                      src="/images/Landmark/Trade-Park.webp"
                      alt="Ashiana Landmark 1"
                    />
                  </div>
                  <div>
                    <h6 className="text-center">TRADE PARK</h6>
                    <p className="text-center mb-0">
                      Showrooms, Offices & Restaurants, <br /> Undri
                    </p>
                  </div>
                </div>
                <div className="landmark-content-box">
                  <div className="landmark-img">
                    <img
                      src="/images/Landmark/Marketplace.webp"
                      alt="Ashiana Landmark 1"
                    />
                  </div>
                  <h6 className="text-center">THE MARKETPLACE</h6>
                  <p className="text-center mb-0">
                    Retail & Offices, <br /> Undri
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 custom-margin-2">
              <h5 class="text-center subtitle_spacing">
                NEARING POSSESSION - GRADE ‘B’ COMMERCIAL PROJECTS
              </h5>
              <div className="landmark-main">
                <div className="landmark-content-box">
                  <div className="landmark-img">
                    <img
                      src="/images/Landmark/KP-Square.webp"
                      alt="Ashiana Landmark 1"
                    />
                  </div>
                  <div>
                    <h6 className="text-center">KP SQUARE</h6>
                    <p className="text-center mb-0">
                      Showrooms & Offices <br /> Opp. DoubleTree by Hilton,<br />
                      Chinchwad
                    </p>
                  </div>
                </div>
                <div className="landmark-content-box">
                  <div className="landmark-img">
                    <img
                      src="/images/Landmark/Town-square.webp"
                      alt="Ashiana Landmark 1"
                    />
                  </div>
                  <h6 className="text-center">VTP TOWN SQUARE</h6>
                  <p className="text-center mb-0">
                    Hypermarket, Showrooms & Offices <br /> Baner Next,
                    Mahalunge
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Landmarks;
