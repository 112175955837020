import React from "react";
import Slider from "react-slick";

import Enquirynow from "./Enquirynow";

const Banner = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: true,
    nextArrow: (
      <div className="slick-next">
        <i className="bi bi-chevron-right"></i>
      </div>
    ),
    prevArrow: (
      <div className="slick-prev">
        <i className="bi bi-chevron-left"></i>
      </div>
    ),
  };
  return (
    <>
      <section className="banner_section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-9 col-md-8 col-12 px-0 desktop-banner">
              <Slider {...settings}>
                <img src="/images/Banners/Altitude-banner-1.webp" />
                <img src="/images/Banners/Altitude-banner-2.webp" />
                <img src="/images/Banners/Altitude-banner-3.webp" />
              </Slider>
            </div>
            <div className="col-lg-9 col-md-8 col-12 px-0 mobile-banner">
              <Slider {...settings}>
                <img clas src="/images/Banners/Altitude-banner-Mob1.webp" />
                <img clas src="/images/Banners/Altitude-banner-Mob2.webp" />
                <img clas src="/images/Banners/Altitude-banner-Mob3.webp" />
              </Slider>
            </div>
            <div className="col-lg-3 col-md-4 col-12 px-0 form-container">
              <Enquirynow showCallbackDropdown={false} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
