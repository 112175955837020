import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import Layout from '../layout/layout';
import Home from '../pages/Home';
import NotFound from '../pages/NotFound';
import Privacy from '../pages/Privacy';
import ThankYou from '../pages/ThankYou';

const router = () => createBrowserRouter(
    createRoutesFromElements(
        <>
        <Route element={<Layout />}>
            <Route index path='/' element={<Home />} />
            <Route path='/privacy-policy' element={<Privacy />} />
            <Route path='/thank-you' element={<ThankYou />} />
            <Route path='/privacy-policy/index.html' element={<Navigate to={'/privacy-policy'}/>} />
            <Route path='/slide-page/home/' element={<Navigate to={'/'}/>} />
            <Route path='/slide/home2/' element={<Navigate to={'/'}/>} />
            <Route path='/slide/home/' element={<Navigate to={'/'}/>} />
            <Route path='/?s={search_term_string}' element={<Navigate to={'/'}/>} />
            <Route path='/home-21-sep-23/' element={<Navigate to={'/'}/>} />
        </Route>
        <Route path="*" element={<NotFound />} />
        </>
    )
);

export default router;