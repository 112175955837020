import React from "react";
import Enquirynow from "./Enquirynow";

const AltitudeEnquiryform = () => {
  return (
    <>
      <section className="altitudeform_section">
        <div className="side_spacing section_spacing overlay-dark-3">
          <div className="row">
            <div className="col-md-4">
              <div className="altitude-location">
                <div class="row">
                  <div class="col">
                    <h2 className="text-center title_spacing text-white">
                      LOCATION
                    </h2>
                    <div class="altitude-content plot-address">
                      <div class="altitude-icon altitude-loc-icon">
                        <img
                          src="/images/location-dot-solid.webp"
                          alt="Location Icon"
                        />
                      </div>
                      <p class="text-white m-0">
                        Plot D & B2, S.No. 18/6, Aundh-Ravet Road, Dange Chowk,
                        Wakad, Pune
                      </p>
                    </div>
                    <div class="altitude-content">
                      <div class="altitude-icon">
                        <img src="/images/phone-solid.webp" alt="Phone Icon" />
                      </div>
                      <p class="text-white m-0">08040830607</p>
                    </div>
                    <div class="altitude-content mb-0">
                      <div class="altitude-icon">
                        <img
                          src="/images/building-solid.webp"
                          alt="Building Icon"
                        />
                      </div>
                      <p class="text-white m-0">RERA Number: P52100024885</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <Enquirynow />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AltitudeEnquiryform;
