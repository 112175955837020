import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css";

const Gallery = () => {
  return (
    <>
      <section className="gallery_section">
        <div className="side_spacing section_spacing">
          <h2 className=" title_spacing text-center">GALLERY</h2>

          <Swiper
            modules={[Navigation, Pagination, A11y]}
            spaceBetween={0}
            loop={true}
            centeredSlides={true}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            pagination={{ clickable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
            effect="coverflow"
            grabCursor={true}
            slidesPerView={5}
            // slidesPerView="auto"
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 150,
              modifier: 2.5,
              slideShadows: true,
            }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              767: {
                slidesPerView: 3,
              },
              990: {
                slidesPerView: 5,
              },
              1024: {
                slidesPerView: 5,
              },
              1440: {
                slidesPerView: 5,
              },
              1920: {
                slidesPerView: 5,
              },
            }}
          >
            <SwiperSlide>
              <div class="content swiper-slide">
                <div className="swiper-img">
                  <img src="/images/ProjectGallery/Swiper-img1.webp" alt="" />
                </div>
                <div class="text-content">
                  <p>14 Storey Commercial Edifice</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="content swiper-slide">
                <div className="swiper-img">
                  <img src="/images/ProjectGallery/Swiper-img2.webp" alt="" />
                </div>
                <div class="text-content">
                  <p>400 Feet of Landscaped Frontage</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="content swiper-slide">
                <div className="swiper-img">
                  <img src="/images/ProjectGallery/Swiper-img3.webp" alt="" />
                </div>
                <div class="text-content">
                  <p>Stunning Tinted Glass Facade with DGU Glass</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="content swiper-slide">
                <div className="swiper-img">
                  <img src="/images/ProjectGallery/Swiper-img4.webp" alt="" />
                </div>
                <div class="text-content">
                  <p>Italian Marble Double Height Entrance Lobby</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="content swiper-slide">
                <div className="swiper-img">
                  <img src="/images/ProjectGallery/Swiper-img5.webp" alt="" />
                </div>
                <div class="text-content">
                  <p>Lobby with Waiting Area & Café</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="content swiper-slide">
                <div className="swiper-img">
                  <img src="/images/ProjectGallery/Swiper-img6.webp" alt="" />
                </div>
                <div class="text-content">
                  <p>Leisure Seating on Top Terrace</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="content swiper-slide">
                <div className="swiper-img">
                  <img src="/images/ProjectGallery/Swiper-img7.webp" alt="" />
                </div>
                <div class="text-content">
                  <p>Al-fresco Solar Powered Workstations on Top Terrace</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="content swiper-slide">
                <div className="swiper-img">
                  <img src="/images/ProjectGallery/Swiper-img8.webp" alt="" />
                </div>
                <div class="text-content">
                  <p>Landscaped Gardens on Top Terrace</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="content swiper-slide">
                <div className="swiper-img">
                  <img src="/images/ProjectGallery/Swiper-img9.webp" alt="" />
                </div>
                <div class="text-content">
                  <p>Lawn with Amphitheater on Top Terrace</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="content swiper-slide">
                <div className="swiper-img">
                  <img src="/images/ProjectGallery/Swiper-img10.webp" alt="" />
                </div>
                <div class="text-content">
                  <p>De-stress Zones on Top Terrace</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="content swiper-slide">
                <div className="swiper-img">
                  <img src="/images/ProjectGallery/Swiper-img11.webp" alt="" />
                </div>
                <div class="text-content">
                  <p>
                    Indoor Gaming Room with Pool Table, Table Tennis & Foosball
                    on Top Terrace
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <div class="swiper-button-prev swiper-button-custom">
              <i class="bi bi-chevron-left"></i>
            </div>
            <div class="swiper-button-next swiper-button-custom">
              <i class="bi bi-chevron-right"></i>
            </div>
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Gallery;
