import React from "react";
import Header from "./Header";
import Disclaimerfooter from "./Disclaimerfooter";
import MobileContactBtn from "./MobileContactBtn";

const Privacypolicy = () => {
  return (
    <>
      <div id="privacy-policy" class="privacy-policy-content"></div>
      <div className="context-text side_spacing">
        <h1>Privacy Policy</h1>
        <p>
          VTP Group/ VTP Realty is committed to maintaining the privacy and
          accuracy of information that has been provided on this site. All
          information is provided with the complete knowledge of owner and
          appropriate measures have been taken to ensure that user’s credentials
          are not misused, accidentally destroyed or lost within the environment
          of VTP Group/ VTP Realty . Though care has been taken in the
          production of information on this website, VTP Group/ VTP Realty will
          not be responsible and accept any liability for loss incurred in
          anyway whatsoever, by anyone who may seek to depend on the information
          contained herein.
        </p>
        <strong>What this Privacy Policy Covers</strong>
        <ul>
          <li>
            This Privacy Policy covers our usage of personally identifiable
            material that is shared by you on our site. This policy also covers
            treatment of any personally identifiable information shared with us.
          </li>
          <li>
            This policy does not apply to the activities of organizations not
            owned or controlled by us, or to people who are not employed or
            managed by us.
          </li>
        </ul>
        <strong>Information Collection and Use</strong>
        <ul>
          <li>
            We use your Personal information only for providing and improving
            the site. By using the site, you agree to the collection and use of
            information in accordance with this Policy.
          </li>
          <li>
            We compile personally identifiable information when you submit the
            enquiry form on our website.
          </li>
          <li>
            While using our site, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you. Personally identifiable information may include but is
            not limited to your name.
          </li>
          <li>
            When you submit a form, we ask for your name, email address and
            phone number apart from what you mention in your comments. Once the
            form is submitted and it reaches us, the information provided by you
            is saved in our database and is not shared with any third party.
          </li>
          <li>
            We use this information to revert to your query and future
            reference, and to contact you about products & services.
          </li>
          <li>
            Like many site operators, we collect information that your browser
            sends whenever you visit our site. This log data may include
            information such as your computer’s internet protocol, IP address,
            browser type, browser version, the pages of our site that you visit,
            the time and date of your visit, the time spent on those pages and
            other statistics
          </li>
        </ul>
        <strong>Information Sharing and Disclosure</strong>
        <ul>
          <li>
            We do not sell, rent or share personally identifiable information
            made available to us.
          </li>
          <li>
            We share personally identifiable information to other companies or
            people only if
            <ul>
              <li>We have consent to share the information; or</li>
              <li>We reply to subpoenas, court orders or legal process; or</li>
              <li>
                We find that your actions on our websites violate the Terms of
                Service
              </li>
            </ul>
          </li>
        </ul>
        <p>
          The content and design of the website are the exclusive property of
          VTP Group/ VTP Realty. No person can use or reproduce or allow any
          other to use or reproduce any image or logo (such as the name) for any
          reason without prior written consent from VTP Group/ VTP Realty. No
          person is allowed to retrieve and display the content available on
          this website for personal, commercial and non-commercial purpose, or
          cannot reproduce, modify or in any way to make commercial use of the
          content. The use of content on this website without permission is
          prohibited. The privacy policy is subject to change and any
          modification to the privacy policy will be communicated here
        </p>
      </div>
    </>
  );
};

export default Privacypolicy;
