import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import CountryDropdown from "./CountryDropdown";
import { toast } from "react-toastify";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import RecaptchaComponent from "./RecaptchaComponent";
const Enquirynow = ({ showCallbackDropdown, onClick, closePopup }) => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const searchParams = new URLSearchParams(location.search);

  const [ otherInfo, SetOtherInfo ] = useState({
    gclid: searchParams.get('gclid') || "",
    sfcid: searchParams.get('sfcid') || "",
    sfid: searchParams.get('sfid') || "",
    utm_source: searchParams.get('utm_source') || "",
    utm_medium: searchParams.get('utm_medium') || "",
    utm_campaign: searchParams.get('utm_campaign') || "",
    sk_referrer: searchParams.get('sk_referrer') || ""
  });
  const [recaptchaErrorhome, setRecaptchaErrorhome] = useState('');
  const [recaptchaVerifiedhome, setRecaptchaVerifiedhome] = useState('');


  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("This is required Field!"),
    last_name: Yup.string().required("This is required Field!"),
    mobile: Yup.string()
    .matches(/^[0-9]+$/, "Mobile number must be a number")
      .required("This is required Field!"),
    email: Yup.string()
      .email("Invalid email address")
      .required("This is required Field!"),
    interest: Yup.string().required("This is required Field!"),
    country_code: Yup.string().required("This is required Field!"),
    term_condition: Yup.string().required("This is required Field!")
  });

  const handlerSubmit = async(formVal) => {
    let CampaignID = "7015j000000Pub1";
		let secondary_source = "google";

		if(formVal.utm_source == "facebook"){
			CampaignID = "7015j000000PukD";
			secondary_source = formVal.utm_source;
		}
    
		if(formVal.utm_source == "facebook" && formVal.utm_campaign == "facebook-altitude"){
			CampaignID = "7015j000000PukD";
			secondary_source = formVal.utm_source;
		}
		if(formVal.utm_source == "linkedin"){
			CampaignID = "7015j000000Punh";
			secondary_source = formVal.utm_source;
		}
		if(formVal.sfid){
			CampaignID = formVal.sfid;
			secondary_source = formVal.utm_source;
		}

		if(formVal.sk_referrer == "international"){
			if(formVal.utm_source == "google"){
				CampaignID = "7015j000000Puaw";
			}
			if(formVal.utm_source == "facebook"){
				CampaignID = "7015j000000Puk8";
			}
			if(formVal.utm_source == "linkedin"){
				CampaignID = "7015j000000Punr";
			}
			secondary_source = formVal.utm_source;
		}

		if(formVal.sk_referrer == "rom"){
			if(formVal.utm_source == "google"){
				CampaignID = "7015j000000Puey";
			}
			if(formVal.utm_source == "facebook"){
				CampaignID = "7015j000000Puk3";
			}
			if(formVal.utm_source == "linkedin"){
				CampaignID = "7015j000000Puo0";
			}
			secondary_source = formVal.utm_source;
		}

    let CampaignNewID = null;
    if(formVal.utm_source !== "google"){
      CampaignNewID = CampaignID;
    }

    const formData = {
      formData:{
        FirstName:formVal.first_name,
        LastName:formVal.last_name,
        Email:formVal.email,
        ProjectName:"Altitude",
        SubProjectName: "Altitude",
        Company: "",
        CampaignId: CampaignNewID,
        PrimarySource:"Digital",
        SecondarySource:secondary_source,
        UtmCampaign:formVal.utm_campaign,
        UtmMedium:formVal.utm_medium,
        UtmSource:formVal.utm_source,
        InterestedFor:formVal.interest,
        CountryCode:formVal.country_code,
        Mobile:formVal.mobile,
        GCLID:formVal.gclid,
        Comment:" ",
    }
  }
    
    const loadingToast = toast.loading("Loading...", {
      position: toast.POSITION.TOP_CENTER
    });
    
    const config = {
      method: "post",
      url: process.env.REACT_APP_BASE_URL+"/salesforce/add",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    const response1 = await axios(config).then(async function (response) {
        toast.dismiss(loadingToast);
        let downloadBrochure = '';
        if(closePopup){
          downloadBrochure = localStorage.getItem("downloadBrochure");
          closePopup.current.click();
        }
        
        if (response.data.status === true) {
          localStorage.setItem("downloadBrochure",downloadBrochure);
          localStorage.setItem('formData', JSON.stringify(formData.formData));
          navigate('/thank-you');
        }
      })
      .catch(function (error) {
        toast.error("Request failed!");
        console.log("Salesforce error -",error);
      });
  }
  const handleRecaptchaVerify = (token) => {    
    setRecaptchaVerifiedhome(token);
    setRecaptchaErrorhome(token);
  };

  

  return (
    <>
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          country_code: "+91",
          mobile: "",
          email: "",
          interest: "",
          term_condition: "",
          callback: "",
          gclid: otherInfo.gclid,
          sfcid: otherInfo.sfcid,
          sfid: otherInfo.sfid,
          utm_source: otherInfo.utm_source,
          utm_medium: otherInfo.utm_medium,
          utm_campaign: otherInfo.utm_campaign,
          sk_referrer: otherInfo.sk_referrer,
          download_brochure: ""
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          
          if(recaptchaErrorhome === ''){
            setRecaptchaErrorhome(false);
            return;
          }
          if(recaptchaErrorhome === false){
            return;
          }
          handlerSubmit(values);
          
        }}
      >
        {(formik) => (
          <Form>
            <div className="banner_form">
              <h2 className="section-title">ENQUIRE NOW</h2>
              <div className="row">
                <div className="col-md-6 col-12 mb-3">
                  <Field type="hidden" id="gclid" name="gclid" />
                  <Field type="hidden" id="sfcid" name="sfcid" />
                  <Field type="hidden" id="sfid" name="sfid" />
                  <Field type="hidden" id="utm_source" name="utm_source" />
                  <Field type="hidden" id="utm_medium" name="utm_medium" />
                  <Field type="hidden" id="utm_campaign" name="utm_campaign" />
                  <Field type="hidden" id="sk_referrer" name="sk_referrer" />
                  <Field type="hidden" id="download_brochure" name="download_brochure" />

                  <Field
                    type="text"
                    id="first_name"
                    name="first_name"
                    className="form-control form-field"
                    placeholder="First Name*"
                    required=""
                  />
                  {formik.touched.first_name && formik.errors.first_name && (
                    <span className="error">{formik.errors.first_name}</span>
                  )}
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <Field
                    type="text"
                    id="last_name"
                    name="last_name"
                    className="form-control form-field"
                    placeholder="Last Name*"
                    required=""
                  />
                  {formik.touched.last_name && formik.errors.last_name && (
                    <span className="error">{formik.errors.last_name}</span>
                  )}
                </div>
                <div className="col-md-5 col-12 mb-3">
                  <CountryDropdown />
                  {formik.touched.country_code &&
                    formik.errors.country_code && (
                      <span className="error">
                        {formik.errors.country_code}
                      </span>
                    )}
                </div>
                <div className="col-md-7 col-12 mb-3">
                  <Field
                    type="text"
                    id="mobile"
                    name="mobile"
                    className="form-control form-field"
                    placeholder="Phone*"
                    required=""
                  />
                  {formik.touched.mobile && formik.errors.mobile && (
                    <span className="error">{formik.errors.mobile}</span>
                  )}
                </div>
                <div className="col-md-12 col-12 mb-3">
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    className="form-control form-field"
                    placeholder="Email*"
                    required=""
                  />
                  {formik.touched.email && formik.errors.email && (
                    <span className="error">{formik.errors.email}</span>
                  )}
                </div>
                <div className="col-md-12 col-12 mb-3">
                  <Field
                    as="select"
                    className="form-select form-field"
                    name="interest"
                  >
                    <option value="">Interested For*</option>
                    <option value="showrooms">Showrooms</option>
                    <option value="offices">Offices</option>
                  </Field>
                  {formik.touched.interest && formik.errors.interest && (
                    <span className="error">{formik.errors.interest}</span>
                  )}
                </div>
                {showCallbackDropdown && (
                  <div className="col-md-12 col-12 mb-3">
                    <Field
                      as="select"
                      className="form-select form-field"
                      name="callback"
                      required=""
                    >
                      <option value="">
                        Which time slot is preferable to you for a callback?
                      </option>
                      <option value="Call me immediately">
                        Call me immediately
                      </option>
                      <option value="7 am - 10 am">7 am - 10 am </option>
                      <option value="10 am – 2 pm">10 am – 2 pm </option>
                      <option value="2 pm – 7 pm">2 pm – 7 pm </option>
                      <option value="7 pm – 9 pm">7 pm – 9 pm </option>
                      <option value="9 pm – 12 midnight">
                        9 pm – 12 midnight
                      </option>
                    </Field>
                    {formik.touched.callback && formik.errors.callback && (
                      <span className="error">{formik.errors.callback}</span>
                    )}
                  </div>
                )}
                <div className="col-md-12 col-12 mb-3">
                  <div className="form-check">
                    <Field
                      className="form-check-input"
                      type="checkbox"
                      name="term_condition"
                      id="flexCheckDefault"
                      required=""
                    />
                    <label
                      className="form-check-label text-white"
                      htmlFor="flexCheckDefault"
                    >
                      I hereby authorize to send notification on SMS, Calls,
                      RCS, WhatsApp.
                    </label>
                    <div>
                      {formik.touched.term_condition &&
                        formik.errors.term_condition && (
                          <span className="error">
                            {formik.errors.term_condition}
                          </span>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-12 mb-3">
                  <RecaptchaComponent onVerify={handleRecaptchaVerify} />
                  <Field className="form-check-input" type="text" name="recaptchaTokan" id="recaptchaTokan" style={{ height:'0', width:'0', visibility:'hidden' }} />
                  {recaptchaErrorhome === false && (
                    <span className="error">This is Required Field!</span>
                  )}
                  {recaptchaVerifiedhome === false && <span className="error">Recaptcha not Verify from Admin Console!</span>}
                </div>
                <button type="submit" className="btn btn-danger form-btn m-auto">
                  SUBMIT
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Enquirynow;
