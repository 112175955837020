import React from "react";

const Projecthighlights = () => {
  return (
    <>
      <section className="highlights_section">
        <div className="overlay-dark">
          <div className="side_spacing section_spacing">
            <h2 className="text-center text-white title_spacing">
              PROJECT HIGHLIGHTS
            </h2>
            <div className="row">
              <div className="col-md-6 col-sm-6 col-12">
                <ul>
                  <li className="list-icon">14 Storey Commercial Edifice</li>
                  <li className="list-icon">
                    Aesthetically Designed by Renowned Architect: Reza Kabul
                  </li>
                  <li className="list-icon">
                    Italian Marble, Grand Double Height Entrance Lobby with{" "}
                    <br />
                    Waiting Area and a café
                  </li>
                  <li className="list-icon">
                    Over 400 Feet of Landscaped Frontage & Visitors Parking
                  </li>
                  <li className="list-icon">
                    Overarching Sustainable Design to Lower Utility Costs &{" "}
                    <br />
                    Enhanced Rental Value
                  </li>
                </ul>
              </div>
              <div className="col-md-6 col-sm-6 col-12">
                <ul>
                  <li className="list-icon">
                    Flexi Workspaces Starting from 480 Sq.Ft. to 3000 Sq.Ft.
                  </li>
                  <li className="list-icon">
                    Stunning Tinted Glass Facade with DGU Glass
                  </li>
                  <li className="list-icon">
                    Professional Building Management System
                  </li>
                  <li className="list-icon">
                    Rooftop Amenities Like Amphitheatre, Landscaped Breakout
                    <br />
                    Zones, Al-Fresco Solar-Powered Workstations, Indoor Games
                    <br />
                    Room for Pool, Table Tennis & a Crèche
                  </li>
                  <li className="list-icon">
                    2 Floors Basement + 3 Levels Podium Parking
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Projecthighlights;
