import React from "react";

const ChooseAltitude = () => {
  return (
    <>
      <section className="choose_altitude_section">
        <div className="side_spacing section_spacing">
          <h2 className="text-white text-center title_spacing custom-title">
            WHY SHOULD YOU CHOOSE ALTITUDE OVER ANY OTHER BUSINESS SPACE?
          </h2>

          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
              <h3
                className="accordion-header accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
                id="flush-headingOne"
              >
                VTP REALTY ADVANTAGE
              </h3>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <ul>
                    <li className="accord-list">
                      With proven leadership in the residential real estate, the
                      brand brings a stunning business spaces project that is
                      destined to disrupt the commercial marketplace.
                    </li>
                    <li className="accord-list">
                      Never to cut corners, the hallmark of Altitude is the awe
                      inspiring elevation and use of tinted glass for the entire
                      façade.
                    </li>
                    <li className="accord-list">
                      A minimal column structure deliberately designed to
                      maximize floor space for larger offices.
                    </li>
                    <li className="accord-list">
                      Thoughtful attention to detail in the internal layout
                      design, choice of specifications and appointment of
                      features is again a testimony to the legacy of VTP Realty.
                      Grandeur in all aspects.
                    </li>
                    <li className="accord-list">
                      Very few commercial and business office towers can boast
                      of an entire top terrace floor dedicated ton exclusive
                      amenities for all the occupants. Amenities like landscaped
                      seating, al-fresco solar powered workstations, leisure
                      seating, lawn with amphitheater for gatherings and
                      de-stress zones and indoor gaming room with pool table,
                      table tennis and football.
                    </li>
                    <li className="accord-list">
                      The building is LEED certified for its high energy
                      efficiency and low carbon footprint.
                    </li>
                    <li className="accord-list">
                      And lastly, but most importantly, VTP Realty leaves no
                      stone unturned to create all its commercial projects into
                      high decibel and footfall zones by cherry picking the
                      top-notch brands as tenants for our retail and business
                      spaces. The leasing, sourcing and transaction assistance
                      is provided as a value added service.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3
                className="accordion-header accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
                id="flush-headingTwo"
              >
                DESIGN & FUNCTIONALITY
              </h3>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <ul>
                    <li className="accord-list">
                      The project is designed by the award winning architect Ar.
                      Reza Kabul.
                    </li>
                    <li className="accord-list">
                      The stunning monolith structure enjoys over 400 feet of
                      main road frontage for prime visibility.
                    </li>
                    <li className="accord-list">
                      The location works in the favour of the project naturally.
                      Altitude is a centrally located landmark, perched
                      premiumly between PCMC and PMC, at downtown Wakad. Well
                      connected on all sides with massive flyovers & 6 lane wide
                      roads, the project is close to the Pune- Mumbai Expressway
                      and has good accessibility to prominent junctions like
                      Pimpri, Chinchwad, Ravet, Aundh, Hinjawadi, Pimple
                      Saudagar, Baner, etc.
                    </li>
                    <li className="accord-list">
                      All showrooms have their own washroom/pantry provision.
                    </li>
                    <li className="accord-list">
                      50% office spaces have the washroom/pantry provision and
                      for the rest of the offices the provisions can be made
                      upon request, at an extra cost.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3
                className="accordion-header accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
                id="flush-headingThree"
              >
                GRADE A DISTINCTION
              </h3>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <ul>
                    <li className="accord-list">
                      50% office spaces have the washroom/pantry provision and
                      for the rest of the offices the provisions can be made
                      upon request, at an extra cost.
                    </li>
                    <li className="accord-list">
                      The glass façade uses DGU which reduces heat transmission
                      and helps save energy for air cooling.
                    </li>
                    <li className="accord-list">
                      A beautiful double height, marble clad grand entrance
                      lobby with a reception desk and WiFi enabled visitors
                      lounge along with a café.
                    </li>
                    <li className="accord-list">
                      Multiple high-speed elevators for quick transit to the
                      upper floors – 6 passenger elevators & 2 service elevators
                    </li>
                    <li className="accord-list">
                      Wide decorative lobbies on every floor with 2.4 meter wide
                      passage for easy movement of people
                    </li>
                    <li className="accord-list">
                      Building Management System (BMS software) which monitors
                      all lighting, water pump operations, lifts etc. thus
                      reducing manpower deployment and reducing the overall
                      operational & energy bills.
                    </li>
                    <li className="accord-list">
                      The building is accessibility friendly which means
                      additional ramps and easy access for the
                      differently-abled.
                    </li>
                    <li className="accord-list">
                      Separate access cards for washrooms to ensure good hygiene
                      among office occupants. Separate washrooms for visitors.
                    </li>
                    <li className="accord-list">
                      Separate smoking zone on each floor.
                    </li>
                    <li className="accord-list">
                      100% DG Back up and separate meter is provided for each
                      unit to record the consumption of electricity
                    </li>
                    <li className="accord-list">
                      Crèche for the employees’ children
                    </li>
                    <li className="accord-list">
                      Integrated fire-fighting system with smoke detectors &
                      sprinklers in each office & showroom
                    </li>
                    <li className="accord-list">
                      Ample car parking for office occupants & additional
                      separate parking for visitors.
                    </li>
                    <li className="accord-list">
                      A network of security cameras and card-controlled access.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChooseAltitude;
