import React from "react";
import Disclaimerfooter from "./Disclaimerfooter";

const Footer = () => {
  return (
    <>
      <section id="footer" className="footer_section">
        <div className=" side_spacing section_top_large">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="footer-scanner-img">
                <img
                  src="/images/footer-imgs/Altitude-scanner-img.png"
                  alt="QR Code 1"
                />
              </div>
              <h6 className="footer-scanner-text">Altitude</h6>
              <div className="d-flex align-items-center justify-content-center gap-1">
                <div>
                  <span className="mahalogo">
                    MahaRERA Reg. No: &nbsp; P52100024885
                  </span>
                </div>
              </div>
              <p className="footer_scan_text">
                For more details visit the <br /> website:&nbsp;
                <a href="https://maharera.mahaonline.gov.in" target="_blank">
                  maharera.mahaonline.gov.in
                </a>
              </p>
            </div>
          </div>
        </div>
        <Disclaimerfooter/>
      </section>
    </>
  );
};

export default Footer;
