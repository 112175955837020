import React from "react";

const Availability = () => {
  return (
    <>
      <section className="availability_section">
        <div className="side_spacing section_spacing">
          <h2 className="text-center title_spacing">
            CHECK AVAILABILITY OF OUR PLAN
          </h2>
          <div className="table-main">
            <table className="table table-bordered plan-table table-container m-auto">
              <thead className="table-dark">
                <tr>
                  <th>Type</th>
                  <th>RERA Carpet Area</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Showroom</td>
                  <td>1087 sqft onward</td>
                  <td>
                    <a
                      className="header-btn custom-btn"
                      style={{ cursor: "pointer" }}
                      data-bs-toggle="modal"
                      data-bs-target="#downloadbrochure"
                    >
                      ENQUIRY NOW
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="zebra-color">Office</td>
                  <td className="zebra-color">480.72 – 673.61 sqft</td>
                  <td className="zebra-color">
                    <a
                      className="header-btn custom-btn"
                      style={{ cursor: "pointer" }}
                      data-bs-toggle="modal"
                      data-bs-target="#downloadbrochure"
                    >
                      ENQUIRY NOW
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
};

export default Availability;
