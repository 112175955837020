import React from "react";

const Commonarea = () => {
  return (
    <>
      <section className="commonarea_section">
        <div className="side_spacing section_spacing">
          <h2 className="text-center title_spacing">COMMON AREA & UTILITY</h2>
          <div className="commonarea-grid-container">
            <div className="commonarea-box">
              <div className="icon-box">
                <img
                  className="commonarea-icon"
                  src="/images/CommonArea/person-booth-solid.png"
                  alt="commonarea_img"
                />
              </div>
              <p>
                High-speed transit system comprising of six-passenger elevators
                that are compatible for the differently-abled & two service
                elevators
              </p>
            </div>
            <div className="commonarea-box">
              <div className="icon-box">
                <img
                  className="commonarea-icon"
                  src="/images/CommonArea/user-shield-solid.png"
                  alt="commonarea_img"
                />
              </div>
              <p>
                Complete compliance with mandatory accessibility features,
                safety & fire-management systems
              </p>
            </div>
            <div className="commonarea-box">
              <div className="icon-box">
                <img
                  className="commonarea-icon"
                  src="/images/CommonArea/battery-full-solid.png"
                  alt="commonarea_img"
                />
              </div>
              <p>
                100% DG backup with 60% diversity and will be charged as per
                usage
              </p>
            </div>
            <div className="commonarea-box">
              <div className="icon-box">
                <img
                  className="commonarea-icon"
                  src="/images/CommonArea/tachograph-digital-solid.png"
                  alt="commonarea_img"
                />
              </div>
              <p>Separate meter is provided</p>
            </div>
            <div className="commonarea-box">
              <div className="icon-box">
                <img
                  className="commonarea-icon"
                  src="/images/CommonArea/border-top-left-solid.png"
                  alt="commonarea_img"
                />
              </div>
              <p>ODU space in each unity</p>
            </div>
            <div className="commonarea-box">
              <div className="icon-box">
                <img
                  className="commonarea-icon"
                  src="/images/CommonArea/spray-can-sparkles-solid.png"
                  alt="commonarea_img"
                />
              </div>
              <p>Sprinklers in each office and showroom</p>
            </div>
            <div className="commonarea-box">
              <div className="icon-box">
                <img
                  className="commonarea-icon"
                  src="/images/CommonArea/restroom-solid.png"
                  alt="commonarea_img"
                />
              </div>
              <p>
                Common washrooms with access card to ensure hygiene and
                cleanliness
              </p>
            </div>
            <div className="commonarea-box">
              <div className="icon-box">
                <img
                  className="commonarea-icon"
                  src="/images/CommonArea/bed-solid.png"
                  alt="commonarea_img"
                />
              </div>
              <p>
                Specially designed restrooms for the differently-abled on every
                floor
              </p>
            </div>
            <div className="commonarea-box">
              <div className="icon-box">
                <img
                  className="commonarea-icon"
                  src="/images/CommonArea/smoking-solid.png"
                  alt="commonarea_img"
                />
              </div>
              <p>Separate smoking zone on each floor</p>
            </div>
            <div className="commonarea-box">
              <div className="icon-box">
                <img
                  className="commonarea-icon"
                  src="/images/CommonArea/fire-solid.png"
                  alt="commonarea_img"
                />
              </div>
              <p>Integrated Firefighting system with smoke detectors</p>
            </div>
            <div className="commonarea-box">
              <div className="icon-box">
                <img
                  className="commonarea-icon"
                  src="/images/CommonArea/solar-panel-solid.png"
                  alt="commonarea_img"
                />
              </div>
              <p>Roof top solar system for powering common areas</p>
            </div>
            <div className="commonarea-box">
              <div className="icon-box">
                <img
                  className="commonarea-icon"
                  src="/images/CommonArea/person-walking-solid.png"
                  alt="commonarea_img"
                />
              </div>
              <p>
                2-meter wide staircase (mandatory 1.5 m) and 2.4 m. wide passage
                for easy movement of people
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Commonarea;
