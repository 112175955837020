import axios from 'axios';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const RecaptchaComponent = ({ onVerify }) => {
    const handleVerify = async (value) => {
        const secretKey = process.env.REACT_APP_RECAPTCHA_SECRET_KEY;

        try {
            const formData = {
                secretKey: secretKey,
                value: value
            }
            const config = {
                method: "post",
                url: process.env.REACT_APP_BASE_URL + "/recaptchverify",
                headers: {
                    "Content-Type": "application/json",
                },
                data: formData,
            };
            const response = await axios(config);
            console.log('response - ', response);
            
            if (response.data.data.success) {
                onVerify(true);
            } else {
                onVerify(false);
            }
        } catch (error) {
            console.error('Error verifying reCAPTCHA:', error);
            throw error;
        }
    };

    return (
        <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={handleVerify}
        />
    );
};

export default RecaptchaComponent;