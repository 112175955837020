import React from "react";

const Constructionupdate = () => {
  return (
    <>
      <section class="update_section">
        <div class="side_spacing section_spacing">
          <h2 class="text-center title_spacing">
            CONSTRUCTION UPDATES OF ALL VTP PROJECTS | JAN 2024
          </h2>
          <h5 class="text-center">
            AFTER SHATTERING SALES RECORDS, WE ARE GEARING UP TO SET THE HIGHEST
            BENCHMARK IN DELIVERY!
          </h5>
          <div class="youtube-video-2">
            <iframe
              src="https://www.youtube.com/embed/vc2k7TWon38?rel=0"
              title="VTP Realty Construction Update | Jan 2024"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </section>
    </>
  );
};

export default Constructionupdate;
